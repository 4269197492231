<template>
  <div>
    <router-view />
  </div>
</template>
<script>


export default {
}
</script>

<style>
@import "./frameworks/css/frameworks.css";
@import "./apps/css/apps.css";
</style>
