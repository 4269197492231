import {createApp} from 'vue'
// Vuetify
import 'vuetify/styles'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import App from './App.vue'
import router from './apps/utils/router';
import apps from './apps/utils/apps';

const vuetify = createVuetify({
    components,
    directives,
})
const app = createApp(App);

app.config.globalProperties.$apps = apps;
app.use(router);
app.use(vuetify)
app.mount('#app');
