const apps = {



    fileUrl(id, width, height) {
        if (!id || id == '') return '';
        if (id.indexOf('http://') == 0 || id.indexOf('https://') == 0 || id.indexOf("//") == 0) {
            return id;
        }

        let url = process.env.VUE_APP_API_BASE_URL;
        let prefix = process.env.VUE_APP_SYSFILE_API_BASE_URL;
        if (prefix && prefix != '') {
            url = prefix;
        }
        url += '/sys/file/get?id=' + id;

        if (width) url += '&width=' + width;
        if (height) url += '&height=' + height;

        return url;
    }
}

export default apps;